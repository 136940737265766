.typingIndicatorBubble {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 40px;
    margin: 0px 8px;
    background-color: #f0f1f1;
    border-radius: 12px;
  }

  .typingIndicatorBubbleDot {
    width: 8px;
    height: 8px;
    margin-right: 4px;
    background-color: #57585a;
    border-radius: 50%;
    animation-name: bounce;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .typingIndicatorBubbleDot:first-of-type {
    margin: 0px 4px;
  }

  .typingIndicatorBubbleDot:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  .typingIndicatorBubbleDot:nth-of-type(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  @keyframes iconEnter {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(6px);
    
    }
  }
  
 
  
  .icon-enter {
    animation: iconEnter 0.5s forwards;
  }
  
  